<script>
import ButtonContact from "./ButtonContact.vue";

export default {
    components: {
        ButtonContact,
    },
    data() {
        return {
            styleButton: {
                color: "#1d4244",
                background: "#21bec5",
            },
        };
    },
};
</script>
<template>
    <div class="navbar-container">
        <div class="custom-container">
            <div class="navbar-content">
                <svg
                    width="76"
                    height="52"
                    viewBox="0 0 76 52"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M45.3488 0.673096H0.190289C0.0889666 0.673096 0.00683594 0.755247 0.00683594 0.85657V9.15985C0.00683594 9.26117 0.0889666 9.34332 0.190289 9.34332H45.3488C45.4501 9.34332 45.5323 9.26117 45.5323 9.15985V0.85657C45.5323 0.755247 45.4501 0.673096 45.3488 0.673096Z"
                        fill="#FF645A"
                    />
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M27.3973 42.2927L45.2984 12.8803C45.4388 12.6493 45.5793 12.6493 45.7197 12.8803L63.3659 42.1729C63.3888 42.2099 63.401 42.2523 63.4015 42.2958C63.4019 42.3393 63.3904 42.3823 63.3683 42.4203C63.3461 42.4584 63.3141 42.4901 63.2755 42.5123C63.2369 42.5345 63.193 42.5463 63.1485 42.5466L39.4262 42.5295C39.271 42.5274 39.1185 42.5652 38.9838 42.6391C38.8491 42.713 38.7369 42.8205 38.6583 42.9507L33.7947 50.8723C33.7696 50.9145 33.756 50.9626 33.7554 51.0118C33.7548 51.0609 33.7672 51.1092 33.7914 51.152C33.8155 51.1948 33.8506 51.2304 33.8929 51.2553C33.9353 51.2802 33.9834 51.2933 34.0326 51.2936C41.0358 51.3344 60.0795 51.3369 67.0827 51.3051C69.2434 51.2916 70.8085 50.9994 71.7779 50.4286C74.8492 48.628 76.0315 44.619 74.1425 41.4798C69.3907 33.5706 61.9775 21.3739 51.903 4.88963C48.8589 -0.0909876 43.2532 -1.11708 39.8625 4.3052C31.9599 16.9346 24.092 29.5971 16.2586 42.2927H27.3973Z"
                        fill="#21BEC5"
                    />
                    <path
                        d="M9.18013 42.2984L38.5095 42.4786C38.5996 42.4786 38.686 42.5144 38.7497 42.5781C38.8134 42.6419 38.8492 42.7282 38.8492 42.8184L33.8275 50.8548C33.8275 51.0904 33.7074 51.2082 33.4673 51.2082C20.6658 51.2354 19.583 51.1475 6.80874 51.1249C1.03992 51.1181 -0.0132932 47.0412 0.000296512 41.9451C0.0184161 38.0629 0.0184161 34.1763 0.000296512 30.2851C-0.0268829 23.8979 1.80773 21.0781 8.28321 21.0713C20.5955 21.0623 38.2083 21.06 50.4934 21.0645C50.5871 21.0645 50.677 21.1018 50.7433 21.168C50.8095 21.2343 50.8468 21.3242 50.8468 21.4179L55.7981 29.5581C55.7981 29.7846 55.6849 29.8978 55.4584 29.8978C55.4584 29.8978 9.40663 29.8978 9.18013 29.8978C8.95364 29.8978 8.84039 30.0111 8.84039 30.2376V41.9587C8.84039 42.1852 8.95364 42.2984 9.18013 42.2984Z"
                        fill="white"
                    />
                    <path
                        d="M21.8587 51.2308C21.955 51.1772 22.036 51.0999 22.0941 51.0062L27.3973 42.2927H16.2586C14.4829 45.1706 12.709 48.0501 10.9369 50.9314C10.7783 51.1851 10.8486 51.312 11.1476 51.312H21.5437C21.6539 51.3122 21.7623 51.2843 21.8587 51.2308Z"
                        fill="#21BEC5"
                    />
                </svg>

                <div class="navbar-options">
                    <a href="">Time’s Up</a>
                    <a href="">Services</a>
                    <a href="">What set us apart</a>
                    <a href="">Portfolio</a>
                    <a href="">Contact</a>
                </div>

                <a href="#contact" class="contact-us">
                    <ButtonContact
                        :style="styleButton"
                        :buttonText="'Contact Us'"
                    />
                </a>

                <a href="#contact" class="contact-us-mobile">
                    <svg
                        width="60"
                        height="60"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M32.8571 26.6H22.1429C21.5745 26.6 21.0295 26.8318 20.6276 27.2444C20.2258 27.6569 20 28.2165 20 28.8C20 29.3835 20.2258 29.9431 20.6276 30.3556C21.0295 30.7682 21.5745 31 22.1429 31H32.8571C33.4255 31 33.9705 30.7682 34.3724 30.3556C34.7742 29.9431 35 29.3835 35 28.8C35 28.2165 34.7742 27.6569 34.3724 27.2444C33.9705 26.8318 33.4255 26.6 32.8571 26.6ZM32.8571 17.8H7.14286C6.57454 17.8 6.02949 18.0318 5.62763 18.4444C5.22576 18.8569 5 19.4165 5 20C5 20.5835 5.22576 21.1431 5.62763 21.5556C6.02949 21.9682 6.57454 22.2 7.14286 22.2H32.8571C33.4255 22.2 33.9705 21.9682 34.3724 21.5556C34.7742 21.1431 35 20.5835 35 20C35 19.4165 34.7742 18.8569 34.3724 18.4444C33.9705 18.0318 33.4255 17.8 32.8571 17.8Z"
                            fill="#21BEC5"
                        />
                        <path
                            d="M7.14286 9H17.8571C18.4255 9 18.9705 9.23179 19.3724 9.64437C19.7742 10.0569 20 10.6165 20 11.2C20 11.7835 19.7742 12.3431 19.3724 12.7556C18.9705 13.1682 18.4255 13.4 17.8571 13.4H7.14286C6.57454 13.4 6.02949 13.1682 5.62763 12.7556C5.22576 12.3431 5 11.7835 5 11.2C5 10.6165 5.22576 10.0569 5.62763 9.64437C6.02949 9.23179 6.57454 9 7.14286 9Z"
                            fill="#FF645A"
                        />
                    </svg>
                </a>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.navbar-container {
    display: flex;
    justify-content: center;
    padding: 0 5%;

    .navbar-content {
        background: #1e1e1e;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 24px;
        padding: 22px 24px 22px 32px;
        border-radius: 64px;
        box-shadow: -7px 9px 30.899999618530273px 0px #00000059;

        .navbar-options {
            display: flex;
            gap: 40px;

            a {
                font-family: "FuturaBook";
                font-size: 20px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
                color: #ffffff;
                text-decoration: none;
            }
        }

        .contact-us-mobile {
            display: none;
        }
    }

    @media (max-width: 1200px) {
        padding: 0 5%;

        .navbar-content {
            background: linear-gradient(
                270deg,
                rgba(0, 0, 0, 0.16) -10.73%,
                rgba(0, 0, 0, 0.5) 103.75%
            );
            box-shadow: 0px 4px 121.6px 0px rgba(0, 0, 0, 0.45);

            position: fixed;
            z-index: 99;
            width: 80%;

            .navbar-options {
                display: none;
                background-color: rgb(0, 0, 0, 0.5);
                flex-direction: column;
                position: fixed;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
                filter: blur(4px);
            }

            .contact-us-mobile {
                display: flex;
            }

            .contact-us {
                display: none;
            }
        }
    }
}
</style>
